
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { isCSVFile } from '@/helpers/validations';

  const notificationStore = namespace('notification');

  @Component
  export default class MenuImporter extends Vue {

    @notificationStore.Mutation
    showError!: (message: string) => void;

    fileChanged(file: File): void {
      isCSVFile(file)
        ? confirm(this.$t('menu.confirmImport') as string) && this.$emit('submit', file)
        : this.showError('menu.chhoseCSV');
    }
  }
