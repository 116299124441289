
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { FileType, File } from '@/interfaces';
  import { AddFilePayload, FindByParentIdPayload } from '@/store/interfaces';
  import FileModal from '@/components/modals/File.vue';

  const filesStore = namespace('files')

  @Component({ components: { FileModal } })
  export default class FilesPage extends Vue {
    @Prop({ required: true }) readonly parentId!: number
    @Prop({ required: true }) readonly type!: FileType;

    showModal = false;
    fileToEdit: File | null = null;

    @filesStore.Getter('getFiles')
    readonly files!: File[];

    mounted(): void {
      const { parentId, type } = this;
      this.getFiles({ parentId, type });
    }

    @filesStore.Action('findByParentId')
    getFiles!: (data: FindByParentIdPayload) => void;

    @filesStore.Action
    removeFile!: (id: number) => void;

    @filesStore.Action
    updateFile!: (data: File) => void;

    @filesStore.Action
    addFile!: (data: AddFilePayload) => void;

    remove(id: number): void {
      if (confirm(this.$t('files.confirm') as string)) {
        this.removeFile(id);
      }
    }

    submit(data: Partial<File> | File): void {
      const { parentId, type } = this;
      !this.fileToEdit?.id
        ? this.addFile({ parentId, data, type })
        : this.updateFile(data as File)
    }

    edit(file: File): void {
      this.fileToEdit = file
      this.showModal = true;
    }

    closeModal(): void {
      this.fileToEdit = null;
      this.showModal = false;
    }
  }
