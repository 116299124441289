
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import draggable from 'vuedraggable'

  import { toTextValuePair } from '@/helpers/array';
  import { MenuCategory, MenuItem, ChangeOrderType, TextValuePair } from '@/interfaces';
  import { AddCategoryPayload, AddItemPayload, AddItemsToCategoryPayload, ChangeOrderPayload, RemoveItemPayload, UpdateItemPayload, UploadMenuPayload } from '@/store/interfaces';
  import { MenuModel } from '@/models';

  import MenuCategoryModal from '@/components/modals/MenuCategory.vue';
  import MenuItemModal from '@/components/modals/MenuItem.vue';
  import MenuImporter from '@/components/MenuImporter.vue';
  import CopyMenuCategoryModal from '@/components/modals/CopyMenuCategory.vue';
  import CheckAccess from '@/components/CheckAccess.vue';

  const menuStore = namespace('menu');

  @Component({ components: {
    MenuCategoryModal, MenuItemModal, MenuImporter, draggable, CopyMenuCategoryModal, CheckAccess,
  } })
  export default class RestaurantMenu extends Vue {
    @Prop({ required: true }) readonly restaurantId!: number;

    showCategoryModal = false;
    showItemModal = false;
    showCategoryCopyModal = false;

    categoryToEdit: MenuCategory | null = null;
    categoryToCopy: MenuCategory | null = null;
    itemToEdit?: MenuItem;
    selectedCategory: MenuCategory | null = null;

    get modifiers(): TextValuePair[] {
      return toTextValuePair(this.menu.getModifiers(), 'name', 'id')
    }

    get allProducts(): TextValuePair[] {
      return toTextValuePair(this.menu.getAllProducts(), 'name', 'id')
    }

    get allComponents(): TextValuePair[] {
      return toTextValuePair(this.menu.getAllComponents(), 'name', 'id')
    }

    @menuStore.State
    menu!: MenuModel;

    @menuStore.Action
    getByRestaurant!: (id: number) => Promise<void>;

    @menuStore.Action
    removeCategory!: (id: number) => Promise<void>;

    @menuStore.Action
    updateCategory!: (category: MenuCategory) => Promise<void>;

    @menuStore.Action
    addCategory!: ({ restaurantId, data}: AddCategoryPayload) => Promise<void>;

    @menuStore.Action
    removeItem!: ({ id, categoryId}: RemoveItemPayload) => Promise<void>;

    @menuStore.Action
    updateItem!: ({ categoryId, data }: UpdateItemPayload) => Promise<void>;
    
    @menuStore.Action
    addItem!: ({ categoryId, data }: AddItemPayload) => Promise<void>;

    @menuStore.Action
    addItemsToCategory!: ({ categoryId, productsIds }: AddItemsToCategoryPayload) => Promise<void>;

    @menuStore.Action
    uploadMenu!: ({ restaurantId, file }: UploadMenuPayload) => Promise<boolean>;

    @menuStore.Action
    changeOrder!: ({ type, ids, parentId }: ChangeOrderPayload) => Promise<void>;

    mounted(): void {
      this.getByRestaurant(this.restaurantId);
    }

    confirmRemovingCategory(categoryId: number): void {
      confirm(this.$t('menu.confirmRemoveCategory') as string) && 
        this.removeCategory(categoryId);
    }

    submitCategory(data: MenuCategory): void {
      const { restaurantId } = this;
      !this.categoryToEdit?.id
        ? this.addCategory({ restaurantId, data })
        : this.updateCategory(data)
    }

    confirmRemovingItem(id: number, categoryId: number): void {
      confirm(this.$t('menu.confirmRemove') as string) && 
        this.removeItem({ id, categoryId })
    }

    submitItem(data: MenuItem): void {
      if (!this.selectedCategory?.id) {
        return;
      }

      !this.itemToEdit?.id
        ? this.addItem({ categoryId: this.selectedCategory.id, data })
        : this.updateItem({ categoryId: this.selectedCategory.id, data });
    }

    showMenuItemModal(item: MenuItem, category: MenuCategory): void {
      this.itemToEdit = item;
      this.selectedCategory = category;
      this.showItemModal = true;
    }

    hideMenuItemEditModal(): void {
      this.itemToEdit = undefined;
      this.selectedCategory = null;
      this.showItemModal = false
    }

    addProductsToModifierGroup(productsIds: number[]): void {
      this.selectedCategory && this.addItemsToCategory({ categoryId: this.selectedCategory.id, productsIds: productsIds.map(item => +item) });
    }

    async importMenuFromFile(file: File): Promise<void> {
      const success = await this.uploadMenu({ restaurantId: this.restaurantId, file });
      success && this.getByRestaurant(this.restaurantId);
    }

    orderChanged(listyType: ChangeOrderType, categoryId: number | null = null): void {
      const items = listyType === ChangeOrderType.category 
        ? this.menu?.getCategories()
        : categoryId && this.menu?.getCategories().find(item => item.id === categoryId)?.items;

      items && this.changeOrder({
        type: listyType,
        ids: items.map((item: MenuCategory | MenuItem) => item.id),
        parentId: categoryId
      });
    }

    showCategoryEditModal(category: MenuCategory): void {
      this.categoryToEdit = category;
      this.showCategoryModal = true;
    }

    hideCategoryEditModal(): void {
      this.categoryToEdit = null;
      this.showCategoryModal = false;
    }

    showCopyModal(category: MenuCategory): void {
      this.categoryToCopy = category;
      this.showCategoryCopyModal = true;
    }

    hideCopyModal(): void {
      this.categoryToCopy = null;
      this.showCategoryCopyModal = false;
    }
  }
