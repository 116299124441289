
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import { SupportedProviders } from "@/interfaces";
  import { RegenerateThumbnailsPayload } from "@/store/interfaces";

  const appStore = namespace('app');
  const menuStore = namespace('menu');

  enum ShowAs {
    icon = 'icon',
    text = 'text'
  }

  @Component
  export default class RegenerateThumbs extends Vue {
    @Prop({ default: null }) conceptId?: number;
    @Prop({ default: ShowAs.text }) showAs!: ShowAs;

    @appStore.State
    private providersList!: string[];

    get providersAndApp(): string[] {
      return [...this.providersList, 'App'];
    }

    @menuStore.Action
    regenerateThumbnails!: (payload: RegenerateThumbnailsPayload) => void;

    rt(type: SupportedProviders): void {
      confirm(this.$t('general.areYouSure') as string) && this.regenerateThumbnails({ type, conceptId: this.conceptId });
    }
  }
