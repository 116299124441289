
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { MenuItemStat } from '@/interfaces';

  const ordersStore = namespace('orders');

  @Component
  export default class TopMenuItems extends Vue {
    @Prop({ required: true }) readonly restaurantId!: number;

    menuItems: MenuItemStat[] = [];

    async mounted(): Promise<void> {
      this.menuItems = await this.getTopMenuItems(this.restaurantId);
    }

    @ordersStore.Action
    getTopMenuItems!: (restaurantId: number) => Promise<MenuItemStat[]>;
  }
