
import { Mixins, Component, Prop } from 'vue-property-decorator';
import FormModal from '@/mixins/formModal';
import { MenuCategoryType, MenuItemType, TextValuePair } from '@/interfaces';

@Component
export default class MenuItemModal extends Mixins(FormModal) {
  @Prop() readonly allModifiers!: TextValuePair[];
  @Prop() readonly allProducts!: TextValuePair[];
  @Prop() readonly allComponents!: TextValuePair[];

  productsIds: TextValuePair[] = [];

  get isModifier(): boolean {
    return Boolean(this.item.categoryType && this.item.categoryType === MenuCategoryType.modifiers);
  }

  get hasSelectedProducts(): boolean {
    return Boolean(this.productsIds && this.productsIds.length > 0);
  }

  get types(): TextValuePair[] {
    return Object.keys(MenuItemType).map((type) => ({
      text: this.$t(`menu.itemTypes.${type}`) as string,
      value: type,
    }));
  }

  get isSet(): boolean {
    return this.data.type === MenuItemType.set;
  }

  mounted(): void {
    const newItem = { ...this.item };

    newItem.modifiers =
      newItem.modifiers &&
      newItem.modifiers.map((itemId: number) =>
        this.allModifiers.find((mod) => itemId == mod.value)
      );
    newItem.components =
      newItem.id &&
      this.item
        .getComponents()
        .map((itemId: number) => this.allComponents.find((compId) => itemId == compId.value));

    this.copyItemToData(newItem);
  }

  transformAndSave(): void {
    this.data.modifiers = this.mapTextValuePairToIds(this.data?.modifiers);
    this.data.components = this.mapTextValuePairToIds(this.data?.components);
    delete this.data.MenuItemCategory;

    this.save();
  }

  private mapTextValuePairToIds(data: TextValuePair[]): number[] {
    return data?.length ? data.map((item: TextValuePair) => +item.value) : [];
  }

  addProductsToCategory(): void {
    const productsIds = this.productsIds
      ?.map((item) => item.value)
      .filter((value) => value !== undefined);
    this.$emit('addProductsToModifierGroup', productsIds);
    this.$emit('close');
  }
}
