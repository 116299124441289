
  import { Component, Mixins } from 'vue-property-decorator';
  import FormModal from '@/mixins/formModal';
  import { TextValuePair } from '@/interfaces';

  @Component
  export default class MenuCategoryModal extends Mixins(FormModal) {
    get categoryTypes(): TextValuePair[] {
      return [
        { value: 'products', text: this.$t('menu.productsCat') as string },
        { value: 'modifiers', text: this.$t('menu.modsCat') as string }
      ]
    }
  }
    
