
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { Restaurant } from '@/interfaces';
  import { CopyRestaurantPayload } from '@/store/interfaces';
  
  import RestaurantModal from '@/components/modals/Restaurant.vue';
  import Files from '@/components/Files.vue';
  import RestaurantMenu from '@/components/Menu.vue';
  import TopMenuItems from '@/components/TopMenuItems.vue';
  import Locations from '@/components/Locations.vue';
  import SynchronizeBtn from '@/components/buttons/SynchronizeBtn.vue';
  import CopyRestaurantModal from '@/components/modals/CopyRestaurant.vue';
  import RegenerateThumbs from '@/components/buttons/RegenerateThumbs.vue';
  import CheckAccess from '@/components/CheckAccess.vue';

  const restaurantsStore = namespace('restaurants');

  @Component({ components: { 
    RestaurantModal, Files, RestaurantMenu, TopMenuItems, Locations, SynchronizeBtn, CopyRestaurantModal,
    RegenerateThumbs, CheckAccess,
  } })
  export default class RestaurantFeedPage extends Vue {
    restaurant: Restaurant = {} as Restaurant;
    editRestaurantModal = false;
    copyRestaurantModal = false;

    mounted(): void {
      this.loadRestaurant();
    }

    get restaurantId(): number {
      return +this.$route.params.restaurantId;
    }

    @Watch('restaurantId')
    onRestaurantIdChanged(): void {
      this.loadRestaurant();  
    }

    @restaurantsStore.Action
    getRestaurant!: (id: number) => Promise<Restaurant>

    @restaurantsStore.Action
    removeRestaurant!: (id: number) => Promise<void>

    @restaurantsStore.Action
    updateRestaurant!: (data: Restaurant) => Promise<Restaurant>

    @restaurantsStore.Action
    copyRestaurant!: (data: CopyRestaurantPayload) => Promise<Restaurant>

    async loadRestaurant(): Promise<void> {
      this.restaurant = await this.getRestaurant(this.restaurantId);
    }

    remove(): void {
      if (confirm(this.$t('restaurants.removeConfirm') as string)) {
        this.removeRestaurant(this.restaurant.id);
        this.$router.push({ name: 'restaurants' }).catch(() => null);
      }
    }

    async update(data: Restaurant): Promise<void> {
      this.restaurant = await this.updateRestaurant(data);
    }

    async copy(data: Restaurant): Promise<void> {
      const restaurant = await this.copyRestaurant({
        name: data.name,
        baseId: data.id
      });
      
      restaurant && this.$router.push({ name: 'restaurant', params: { restaurantId: ''+restaurant.id } }).catch(() => null);
    }
  }
