
  import { Component, Mixins, Prop } from 'vue-property-decorator'
  import FormModal from '@/mixins/formModal'
  import { namespace } from 'vuex-class';
  import { Restaurant, TextValuePair } from '@/interfaces';
  import { CopyMenuCategoryPayload } from '@/store/interfaces';

  const restaurantsStore = namespace('restaurants');
  const menuStore = namespace('menu');

  @Component
  export default class CopyMenuCategoryModal extends Mixins(FormModal) {
    @Prop({ required: true }) categoryId!: number;
    @Prop({ required: true }) restaurantId!: number;

    destinationId = 0;
    copyWithMods = true;

    @restaurantsStore.Getter('getAllRestaurants')
    readonly allRestaurants!: Restaurant[];

    get concepts(): TextValuePair[] {
      return this.allRestaurants?.filter(item => item.id !== this.restaurantId)
        .map(item => ({
        text: item.name,
        value: item.id
      }));
    }

    mounted(): void {
      this.getRestaurants();
    }

    @restaurantsStore.Action
    getRestaurants!: () => Promise<void>;

    @menuStore.Action
    copyMenuCategory!: (data: CopyMenuCategoryPayload) => Promise<void>

    async copy(): Promise<void> {
      await this.copyMenuCategory({
        fromRestaurant: this.restaurantId,
        toRestaurant: this.destinationId,
        categoryId: this.categoryId,
        copyWithMods: this.copyWithMods
      });

      this.closeModal();
    }
  }
