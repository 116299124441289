
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { Location } from '@/interfaces';

  const conceptsStore = namespace('concepts');

  @Component
  export default class Locations extends Vue {
    @Prop({ required: true }) readonly restaurantId!: number;

    locations: Location[] = [];

    async mounted(): Promise<void> {
      this.locations = await this.getLocations(this.restaurantId);
    }

    @conceptsStore.Action
    getLocations!: (restauratnId: number) => Promise<Location[]>;
  }
